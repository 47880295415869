// Generated by Framer (e42045d)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { withClass } from "../codeFile/Cta_teste.tsx";
const ImageWithClass = withClass(Image);

const serializationHash = "framer-dwKn2"

const variantClassNames = {kfqiOrkX4: "framer-v-6b7jvb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "kfqiOrkX4", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://play.google.com/store/apps/details?id=br.com.impact.bank"} nodeId={"kfqiOrkX4"} openInNewTab><ImageWithClass {...restProps} {...gestureHandlers} as={"a"} background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 1214, pixelWidth: 4096, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/gOhuXcCIyENgt0b22ecJrJslfM.png", srcSet: "https://framerusercontent.com/images/gOhuXcCIyENgt0b22ecJrJslfM.png?scale-down-to=512 512w,https://framerusercontent.com/images/gOhuXcCIyENgt0b22ecJrJslfM.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/gOhuXcCIyENgt0b22ecJrJslfM.png?scale-down-to=2048 2048w,https://framerusercontent.com/images/gOhuXcCIyENgt0b22ecJrJslfM.png 4096w"}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-6b7jvb", className, classNames)} framer-4an21y`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"kfqiOrkX4"} ref={ref ?? ref1} style={{...style}}/></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-dwKn2.framer-4an21y, .framer-dwKn2 .framer-4an21y { display: block; }", ".framer-dwKn2.framer-6b7jvb { height: 51px; position: relative; text-decoration: none; width: 170px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 51
 * @framerIntrinsicWidth 170
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerI4q8nZlz8: React.ComponentType<Props> = withCSS(Component, css, "framer-dwKn2") as typeof Component;
export default FramerI4q8nZlz8;

FramerI4q8nZlz8.displayName = "Google play";

FramerI4q8nZlz8.defaultProps = {height: 51, width: 170};

addFonts(FramerI4q8nZlz8, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})